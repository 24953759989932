<template>
  <div class="container_con" style="background: none;">
      
    <el-card style="margin-bottom: 15px;">
      <div class="con_title"><span></span> 入库信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="单据编号前缀" prop="no">
            <el-select v-model="form.no" placeholder="请选择"
                style="width: 300px; margin-right: 15px;" filterable>
                <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                    :key="index" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="入库日期：" prop="purchase_storage_date">
          <el-date-picker
            v-model="form.purchase_storage_date"
            type="date" value-format="YYYY-MM-DD"
            placeholder="选择日期"
            style="width:300px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保管员:" prop="admin_id">
          <el-select v-model="form.admin_id" placeholder="请选择"
              style="width:300px" filterable>
              <el-option :label="item.full_name+'('+item.department_name+')' + '-' + item.mnemonic_code" v-for="(item,index) in rylist"
                  :key="index" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
            <el-input v-model="form.remark" placeholder="请输入备注" style="width:300px" />
        </el-form-item>
      </el-form>
    </el-card>
    <!-- <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 采购计划</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{purchase_plan.purchase_plan_date}}</el-col>
              <el-col :span="6">联系电话：{{purchase_plan.merchant.mebl_operation_mode}}</el-col>
              <el-col :span="6">部门：{{purchase_plan.department_name}}</el-col>
              <el-col :span="6">供货单位：{{purchase_plan.merchant.merchant_name}}</el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="6">单据编号：{{purchase_plan.purchase_plan_no}}</el-col>
              <el-col :span="6">单位编号：{{purchase_plan.merchant.merchant_name}}</el-col>
              <el-col :span="6">采购员：{{purchase_plan.admin_name}}</el-col>
          </el-row>
      </div> -->
      <!-- <div class="" style="margin-top: 10px; font-size: 14px;">
          <div style="padding-bottom: 20px;">采购产品：</div>
          <div class="">
              <el-table :data="data.purchase_acceptance_product" style="width: 100%">
                <el-table-column label="产品编号">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.product_no}}
                    </template>
                </el-table-column>
                <el-table-column label="品名">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.product_name}}
                    </template>
                </el-table-column>
                <el-table-column label="助记码">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.mnemonic_code}}
                    </template>
                </el-table-column>
                <el-table-column label="型号规格">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.model}}
                    </template>
                </el-table-column>
                <el-table-column label="生产厂家">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.manufacturer}}
                    </template>
                </el-table-column>
              </el-table>
          </div>
      </div> -->
    <!-- </el-card> -->
    
    <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 验收信息</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{data.purchase_acceptance_date}}</el-col>
              <el-col :span="6">单据编号：{{data.purchase_acceptance_no}}</el-col>
              <el-col :span="6">验收员：{{data.inspector_name}}</el-col>
          </el-row>
          <div style="padding-bottom: 20px;">验收产品：</div>
          <div class="">
              <el-table :data="list" style="width: 100%" >
                <el-table-column label="产品编号">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.product_no}}
                    </template>
                </el-table-column>
                <el-table-column label="品名">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.product_name}}
                    </template>
                </el-table-column>
                <el-table-column label="型号规格">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.model}}
                    </template>
                </el-table-column>
                <el-table-column label="生产厂家">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.manufacturer}}
                    </template>
                </el-table-column>
                <el-table-column label="单位">
                    <template v-slot="scope">
                      {{scope.row.pack_unit_name}}
                    </template>
                </el-table-column>
                <el-table-column label="验收数量">
                    <template v-slot="scope">
                      {{scope.row.product_num}}
                    </template>
                </el-table-column>
                <el-table-column label="验收单价">
                    <template v-slot="scope">
                      {{scope.row.product_price}}
                    </template>
                </el-table-column>
                <el-table-column label="金额">
                    <template v-slot="scope">
                      {{(parseFloat(scope.row.product_price)*parseFloat(scope.row.product_num) || 0).toFixed(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="产品批号" width="150">
                  <template v-slot="scope">
                    <div>
                      <el-input v-model="scope.row.batch_number" placeholder="请输入产品批号" style="width:100%" />
                    </div>
                  </template>
                </el-table-column>
                
                <el-table-column label="仓库货架" width="150">
                    <template v-slot="scope">
                      <el-select v-model="scope.row.warehouse_id" placeholder="请选择货位" clearable
                          style="width:100%" filterable>
                          <el-option :label="item.cargo_space_name" v-for="(item,index) in hwlist"
                              :key="index" :value="item.id"></el-option>
                      </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="有效期至"  width="170">
                   <template v-slot="scope">
                      <div>
                          <el-date-picker
                            v-model="scope.row.valid_until"
                            type="date" value-format="YYYY-MM-DD"
                            placeholder="选择日期(长期不选)"
                            style="width:100%"  :shortcuts="shortcuts"
                          >
                          </el-date-picker>
                      </div>
                  </template>
                </el-table-column>
				
				
				
                <el-table-column label="验收结论">
                    <template v-slot="scope">
                      {{scope.row.acceptance_conclusion}}
                    </template>
                </el-table-column>
                <el-table-column label="质量情况">
                    <template v-slot="scope">
                      {{scope.row.quality_situation}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证名">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.register_cert_name}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证号">
                    <template v-slot="scope">
                      {{scope.row.purchase_plan_product.product.register_cert_no}}
                    </template>
                </el-table-column>
                
              </el-table>
          </div>
      </div>
    </el-card>  
    
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addpurchaseAccept",
  data() {
    return {
      id: '',
      is_ajax: false,
      purchase_plan:'',
      form: {
        no:''  
      },
      rules: {
        purchase_storage_date: [
          { required: true, message: "入库日期不能为空",trigger: "blur"}
        ],
        no: [
          { required: true, message: "编号前缀不能为空",trigger: "blur"}
        ],
        admin_id: [
          { required: true, message: "保管员不能为空",trigger: "blur"}
        ],
        // warehouse_id: [
        //   { required: true, message: "仓库货架不能为空",trigger: "blur"}
        // ]
      },
      zjlist:[],
      rylist:[],
      hwlist:[],
      list:'',
      data:'',
	  shortcuts: [{
	    text: '2099-12-31',
	    value:'2099-12-31'
	  }, {
	    text: '长效期',
	    value:'null'
	  }],
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      this.get_data();
      this.form.purchase_storage_date=this.today_val()
      this.getOptions();
      
  },
  methods: {
    
    /** 提交按钮 */
    submitForm: function(form) {
      this.$refs[form].validate((valid)  => {
        if (valid) {
          
          if(form=='form'){
              let _data={...this.form};
              _data.purchase_acceptance_id=this.id;
              let arr=[];
              for(let i=0;i<this.list.length;i++){
                  if(!this.list[i].warehouse_id){
                      this.$message.error('请选择仓库货架');
                      return false;
                  }
                  arr.push({
					  valid_until:this.list[i].valid_until=='Invalid Date'?null:(this.list[i].valid_until || null),
					  batch_number:this.list[i].batch_number,
                      purchase_acceptance_product_id:this.list[i].id,
                      warehouse_id:this.list[i].warehouse_id,
                  })
              }
              this.is_ajax=true;
              _data.product_array=JSON.stringify(arr)
              this.$httpPost("/backend/PurchaseStorage/save", _data).then((res) => {
                  if (res.status == 200) {
                      this.$message.success('新增成功');
                      this.$router.go(-1)
                  } else {
                      this.$message.error(res.message);
                  }
                  this.is_ajax=false;
              }).catch((err) => {
                  console.log(err);
                  this.is_ajax=false;
              });
          }
        }
      });
    },
    get_data(){
        this.$httpGet("/backend/PurchaseAcceptance/read", {id:this.id}).then((res) => {
            if (res.status == 200) {
                this.data=res.data;
                this.purchase_plan=res.data.purchase_plan[0];
                let _data = res.data;
                this.list=res.data.purchase_acceptance_product;
                this.data = _data;
                this.remoteMethod()
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '采购入库'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(this.zjlist.length>0){
                    this.form.no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        this.$httpGet("/backend/sys.Admin/keeperList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data.data;
                
                // 默认选中第一个
                // if(this.rylist.length>0){
                //     this.form.admin_id=this.rylist[0].id
                // }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    remoteMethod(keyword){
        this.$httpGet("/backend/Warehouse/index", {size:10000000,title:keyword}).then((res) => {
            if (res.status == 200) {
                this.hwlist = res.data.data;
                // 默认选中第一个
                for(let i=0;i<this.list.length;i++){
                  this.list[i].warehouse_id=this.hwlist.length>0?this.hwlist[0].id:''
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;
  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }
  .el-table {
    margin: 30px 0;
  }
}
.top_box {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>
